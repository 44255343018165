function Button(props) {
    return <input
      type={props.type}
      name={props.name}
      className={props.className}
      value={props.label}
      onClick={props.onClick}
    />
}

export default Button;