import FadeInSection from "../components/FadeInSection";
import React from 'react';
import { useMatch, useLocation } from "react-router-dom";
import { useTitle } from "../App";

function Main(props) {
  useTitle('Sweet Remaps');

  const {setFlow, setFlowStep, flow} = props
  const passwordResetMatch = useMatch('/password_reset')

  React.useEffect(() => {
    if (passwordResetMatch) {
      setFlow({
        type: "password_reset"
      })
    }
  }, [passwordResetMatch, setFlow]);

  React.useEffect(() => {
    if (flow?.type === 'password_reset') {
      setFlowStep({
        type: "password_reset",
        title: "Set a new password",
        actionLabel: "confirm"
      })
    }
  }, [flow, setFlow, setFlowStep])

  return (
    <div className="main content">
      <section>
        <FadeInSection>
          <div className="box">
          <span className="indicator"></span>			
          <p className="content">Australian owned and operated, we are <em>calibration experts</em> in Diesel and Petrol engines, whether it be a tow & touring or a performance tune we will help you maximise your engine's full potential.</p>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="box">
          <span className="indicator"></span>
          <p className="content">Our goal is to be your <em>business partner</em> - not your competition, we want you achieve high customer satisfaction leading to repeat and referral business.</p></div>
        </FadeInSection>
        <FadeInSection>
          <div className="box">
          <span className="indicator"></span>
            <p className="content">We guarantee <em>no copied solutions</em> but truly custom tunes to suit each vehicle and to ensure the best possible results,</p>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="box">
          <span className="indicator"></span>
            <p className="content">a <em>stress-free experience</em> with our back-up service, re-tune guarantees and ongoing technical support</p>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className="box">
          <span className="indicator"></span>
            <p className="content">and <em>competitive prices</em> to sweeten our offer.</p>
          </div>
        </FadeInSection>
      </section>
    </div>
  );
}

export default Main;
