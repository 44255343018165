import React from "react"
import Button from "./Button"
import Input from "./Input"
import { Link } from "react-router-dom";

function ForgotPassword(props) {
	const [formState, setFormState]  = React.useState({ email: props.userEmail || ""})
	const [formSubmitStatus, setFormSubmitStatus]  = React.useState()

	async function onSubmit(e) {
		e.preventDefault()

		const response = await fetch(
			"/forgot_password",
			{
				method: 'POST',
				mode: 'same-origin',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json'
				},
				redirect: 'error',
				referrerPolicy: 'strict-origin',
				body: JSON.stringify({
					...formState
				})
			}
		)

		if (!response || response.status !== 200) {
			setFormSubmitStatus("failure")
		} else {
			setFormSubmitStatus("success")
		}
	}

	return (
				<>
					<p>Please enter the email you used to sign up and we will send further instructions there.</p>
					<form onSubmit={onSubmit}>
						<Input
							name="email"
							type="email"
							label="Email"
							value={props.userEmail || formState.email || ""}
							onChange={(value) => setFormState({ ...formState, email: value })}
						/>
						{formSubmitStatus === "success" && <div className="alert success top">
							<p>We sent further password reset instructions to <b>{formState.email}</b>. It can take several minutes for them to arrive.</p>
							<p>If you didn't receive the instructions, please check the spam folder, <a onClick={() => setFormSubmitStatus(undefined)}>try again</a> or <Link onClick={props.onCancel} to="/contact">contact us</Link>.</p>
						</div>}
						{formSubmitStatus === "failure" && <div className="alert failure top">Oh no! Something went wrong, please try again later.</div>}
						<div className="controls">
							{<Button type="button" name="close" className="button outlined" label={formSubmitStatus === "success" ? "done" : "cancel"} onClick={props.onCancel}/>}
							{formSubmitStatus !== "success" && <Button type="submit" name="done" className="button outlined" label={props.buttonLabel}/>}
						</div>
					</form>
					<p><a onClick={props.onSignIn}>Back to sign in</a></p>
				</>
		)
}

export default ForgotPassword;