import React from "react"

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
  
    const domRef = React.useRef();
  
    React.useEffect(() => {
        if (!isVisible && domRef.current) {
            const observer = new IntersectionObserver(entries =>
                entries.forEach(entry => 
                    props.timeout 
                        ? setTimeout(() => setVisible(entry.isIntersecting), props.timeout * 100) 
                        : setVisible(entry.isIntersecting)
                )
            );
        
            observer.observe(domRef.current);

            return () => domRef.current && observer.unobserve(domRef.current)
        }      
    }, [isVisible, setVisible, domRef]);
  
    return (
      <div
        style={props.style}
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  export default FadeInSection;