import React from "react"
import { useTitle } from "../App";
import Button from "../components/Button"
import FadeInSection from "../components/FadeInSection"

function Account(props) {
	useTitle('Account settings');
	return  <div className="main content">
		<section>
		<FadeInSection>
				<div className="box">
				<span className="indicator"></span>		
					<section className="content">
						<h3 data-animation="Account details">Account details</h3>
						<p>
							<span className="working-hours-box">
								<span className="working-hours-row"><span><em>Email:</em></span><span><b>{props.userEmail}</b></span></span>
							</span>
						</p>
					</section>
				</div>
			</FadeInSection>
			<FadeInSection>
				<div className="box">
					<span className="indicator"></span>		
					<div className="content">
						<Button
							type="button"
							className="button outlined"
							name="sign out"
							label="sign out"
							onClick={props.onSignOut}
						/>
					</div>	
				</div>
			</FadeInSection>
		</section>
	</div>
}

export default Account;