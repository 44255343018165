import React from "react"
import Button from "./Button"

function SignOut(props) {
	const [formSubmitStatus, setFormSubmitStatus] = React.useState()

	async function onSubmit(e) {
		e.preventDefault()

		const response = await fetch(
			"/sign_out",
			{
				method: 'DELETE',
				mode: 'same-origin',
				cache: 'no-cache',
				credentials: 'same-origin',
				redirect: 'error',
				referrerPolicy: 'strict-origin'
			}
		)

		if (response && response.status === 200) {
			props.onSessionStateChange(false)
			props.onAccountStateChange(undefined)
			props.onWinOLSCustomerNumberStateChange(undefined)
			props.onUserEmailChange(undefined)
		} else {
			setFormSubmitStatus("failure")
		}
	}

	return<form onSubmit={onSubmit}>
		{formSubmitStatus === "failure" && <div className="alert failure top">Oh no! Something went wrong, please try again later.</div>}
		<div className="controls">
			<Button type="button" name="cancel" className="button outlined" label="cancel" onClick={props.onCancel}/>
			<Button type="submit" name="sign_out" className="button outlined" label={props.buttonLabel}/>
		</div>
	</form>

}

export default SignOut;