import React from "react"
import Button from "./Button"
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Link } from "react-router-dom";
// import { useParams } from "react-router-dom"

function Checkout(props) {
	//let { id } = useParams()
	//const [order, setOrder]  = React.useState(props.content && props.content.order)
	const [checkoutStatus, setCheckoutStatus]  = React.useState()
	const [checkoutError, setCheckoutError]  = React.useState()
	const [amount] = React.useState(`${props.basketState.totalCost / 100}.00`)

	//TODO: Make sure that amount is of the correct currency
	const createOrder = React.useCallback(
		async (_data, actions) => {
			const response = await fetch(
				"/winols/credits/payment",
				{
					method: 'POST',
					mode: 'same-origin',
					cache: 'no-cache',
					credentials: 'same-origin',
					headers: {
						'Content-Type': 'application/json'
					},
					redirect: 'error',
					referrerPolicy: 'strict-origin',
					body: JSON.stringify({
						cost: props.basketState.totalCost,
						credits: props.basketState.amountOfWinOLSCredits,
					})
				}
			)
		
			if (!(response && response.status === 200)) {
				console.error(response)
				throw Error("Can't create WinOLS credits order")
			}

			const paymentData = await response.json()

			console.log(paymentData)
		
			return actions.order.create({
				intent: "capture",
				purchase_units: [
					{
						description: `${props.basketState.amountOfWinOLSCredits} WinOLS credits for Sweet Remaps`,
						amount: {
							currency_code: props.paypalCurrencyCode,
							value: amount,
						},
						payee: {
							email_address: props.paypalMerchantEmail,
							merchant_id: props.paypalMerchantId
						},
						custom_id: paymentData.id,
					},
				],
				application_context: {
					brand_name: "Sweet Remaps",
					shipping_preference: "NO_SHIPPING",
					payment_method: {
						payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
					}
				},
			});
		},
		[props]
	);

	const onApprove = React.useCallback(
		async (data, actions) => {
			console.log(data)
			setCheckoutStatus("processing")
			const response = await actions.order.capture()
			if (response && response.status === "COMPLETED") {
				console.log(response)
				setCheckoutStatus("completed")
				setCheckoutError()
			} else {
				console.log(response)
				setCheckoutStatus()
				setCheckoutError("onCapture")
			}
			return response
		}, [setCheckoutStatus, setCheckoutError]
	);

	/* React.useEffect(() => {
		if (!props.content) {
			const { promise, cancel } = cancelable(fetchOrder, [id])
			promise
				.then((order) => setOrder(order))
				.catch((reason) => console.error("Request rejected", reason))
			return () => cancel()
		}
	}, [id]) */

	return (
			<>
				{<div className="Rtable">
					<div className="Rtable-raw">
						<div className="Rtable-cell Rtable-cell--50">Items:</div>
						<div className="Rtable-cell Rtable-cell--50 Rtable-cell--right">{props.basketState.amountOfWinOLSCredits} WinOLS credits</div>
					</div>
					<div className="Rtable-raw">
						<div className="Rtable-cell Rtable-cell--50">Buyer/Recepient:</div>
						<div className="Rtable-cell Rtable-cell--50 Rtable-cell--right">{props.winOLSCustomerNumberState}</div>
					</div>
					<div className="Rtable-raw">
						<div className="Rtable-cell Rtable-cell--foot Rtable-cell--50">Total cost:</div>
						<div className="Rtable-cell Rtable-cell--foot Rtable-cell--50 Rtable-cell--right">${amount}</div>
					</div>
				</div>}
				{checkoutError && <div className="alert failure top">
					{checkoutError === "onApprove" && (<>
						<p>Something went wrong and your payment wasn't completed. Please make sure you have enough funds or contact us.</p>
					</>)}
					{checkoutError === "onOrder" && (<>
						<p>Something went wrong. Please check if you were charged and <Link onClick={props.onCancel} to="/contact">contact us</Link> if necessary.</p>
						<p><b>Tip:</b> try again using your browser's "private/incognito" window. If it helps, then <a href="https://clear-my-cache.com/detect.html">clearing your browser's cache</a> should resolve the issue.</p>
					</>)}
				</div>}
				{checkoutStatus === "completed" && <div className="alert success top">Payment has been successfully completed!</div>}
				{checkoutStatus === "processing" && <div className="alert info top">Please wait, PayPal is still processing the payment ... </div>}
				<div className="controls" style={{ flexDirection: 'column' }}> 
					{checkoutStatus !== "completed" &&  <PayPalScriptProvider
						options={{
							"client-id": props.paypalClientId,
							currency: props.paypalCurrencyCode,
							"disable-funding": "card"
						}}
					>
						<PayPalButtons
							style={{
								label: props.buttonLabel,
								height: 52
							}}
							disable={checkoutStatus === 'processing'}
							createOrder={(data, actions) => createOrder(data, actions)}
							onApprove={(data, actions) => onApprove(data, actions)}
							onError={(error) => {
								setCheckoutError("onOrder")
								setCheckoutStatus()
								console.error("Transaction error", error)
							}}
						/>
					</PayPalScriptProvider>}
					{checkoutStatus !== "processing" && <Button type="button" name="close" className="button outlined" label={checkoutStatus !== "completed" ? "cancel" : "done"} onClick={props.onCancel}/>}
				</div>
			</>
	)
}

export default Checkout;