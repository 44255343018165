import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import Button from "./Button"
import Input from "./Input"

function PasswordReset(props) {
	const [formState, setFormState]  = React.useState({})
	const [formSubmitStatus, setFormSubmitStatus]  = React.useState()
	const [searchParams] = useSearchParams();

	async function onSubmit(e) {
		e.preventDefault()

		const response = await fetch(
			"/password_reset",
			{
				method: 'POST',
				mode: 'same-origin',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json'
				},
				redirect: 'error',
				referrerPolicy: 'strict-origin',
				body: JSON.stringify({
					...formState,
					token: decodeURIComponent(searchParams.get("token"))
				})
			}
		)

		if (!response || response.status !== 200) {
			setFormSubmitStatus("failure")
		} else {
			setFormSubmitStatus("success")
			const { signOut } = await response.json()

			if (signOut) {
				props.onSessionStateChange(false)
				props.onAccountStateChange(undefined)
				props.onWinOLSCustomerNumberStateChange(undefined)
				props.onUserEmailChange(undefined)
			}
		}
	}

	return (
		<form onSubmit={onSubmit}>
			<Input
				name="password"
				type="password"
				label="New password"
				onChange={(value) => setFormState({ ...formState, password: value })}
			/>
			<Input
				name="password_confirmation"
				type="password"
				label="Password confirmation"
				onChange={(value) => setFormState({ ...formState, password_confirmation: value })}
			/>
			{formSubmitStatus === "success" && <div className="alert success top">
				<p>Your password is reset. Please <a onClick={props.onSignIn}>sign in</a>.</p>
			</div>}
			{formSubmitStatus === "failure" && <div className="alert failure top">Oh no! Something went wrong, please try again later.</div>}
			<div className="controls">
				{<Button type="button" name="close" className="button outlined" label={formSubmitStatus === "success" ? "done" : "cancel"} onClick={props.onCancel}/>}
				{formSubmitStatus !== "success" && <Button type="submit" name="confirm" className="button outlined" label={props.buttonLabel}/>}
			</div>
		</form>
	)
}

export default PasswordReset;


