import { NavLink } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";

function MenuItem(props) {
    const location = useLocation();

	const getItemClass = React.useCallback(({ isActive }) => {
		if (props.subItemsPaths) {
			return props.subItemsPaths.find((path) => location.pathname === path || location.pathname === `${path}/`) ? "active" : undefined
		} else {
			return isActive || location.pathname === `${props.url}/` ? "active" : undefined
		}
	}, [props.subItemsPaths, location])

	return <li className={props.className}>
		<NavLink
			to={props.url}
			onClick={props.onClick}
            className={getItemClass}
			end
		>
            <span className="base"></span>
			<p>{props.text}</p>
			<span className="indicator"></span>
		</NavLink>

		{props.subItems && <nav className="submenu">
			{props.subItems}
		</nav>}
	</li>
}

export default MenuItem;