import React from "react"
import Button from "../components/Button"
import './Credits.css';
import evc from '../evc.svg';
import FadeInSection from "../components/FadeInSection";
import { useTitle } from "../App";

function Credits(props) {
	useTitle('WinOLS credits');
	const [winolsCreditBalance, setWinolsCreditBalance]  = React.useState()

	React.useEffect(() => {
		(async function() {
			if (props.winOLSCustomerNumber) {
				const response = await fetch(
					`/data/winols/balance?` + new URLSearchParams({
						winols_customer_number: props.winOLSCustomerNumber
					}),
					{
						method: 'GET',
						mode: 'same-origin',
						cache: 'no-cache',
						credentials: 'same-origin',
						redirect: 'error',
						referrerPolicy: 'strict-origin'
					}
				)
				
				if (response.status !== 200) {
					return 
				}
				
				const { balance } = await response.json()
				
				setWinolsCreditBalance(balance);
			}
		})()
  }, [setWinolsCreditBalance, props.winOLSCustomerNumber])
  
	return <div className="main content">
		<section>
		{props.winOLSCustomerNumber ? <FadeInSection>
				<div className="box">
				<span className="indicator"></span>		
					<section className="content">
						<h3 data-animation="WinOLS details">WinOLS details</h3>
						<p>
							<span className="working-hours-box">
								<span className="working-hours-row"><span><em>Customer number:</em></span><span><b>{props.winOLSCustomerNumber}</b></span></span>
								<span className="working-hours-row"><span><em>Credit balance:</em></span><span><b>{winolsCreditBalance ? `${winolsCreditBalance} xOLS` : "..."}</b></span></span>
							</span>
						</p>
					</section>	
				</div>
			</FadeInSection> : <FadeInSection>
          <div className="box">
          <span className="indicator"></span>
		  <p className="content">
                You can purchase <em>WinOLS credits</em> to use with our WinOLS Reseller Database.
				<br/>
				<br/>
				Sweet Remaps Pty Ltd is <a href="https://www.evc.de/en/check_evc_license.asp?k=m3%2f4SPDxNoMhDbc4%2bi15%2fw%3d%3d">a licensed WinOLS user</a>.
			</p>			
          </div>
        </FadeInSection>}
			<div className="cards">
				{["gogarufi@gmail.com", "luke@sweetremaps.com.au"].includes(props.userEmail) &&
					<FadeInSection timeout={0} >
						<div className="card">
							<div className="column">
								<div className="row">
									<div className="title">
										<span>0</span>
										<span>
											<span>Win<span>OLS</span></span>
											<span>credits</span>
										</span>
									</div>
									<img className="logo" src={evc}/>
								</div>
								<div className="row">
									<Button
										type="button"
										name="buy"
										className="button outlined"
										label="buy"
										onClick={() => props.onBuy(0, 100)}
									/>
									<div className="description">
										<span>$1.00</span>
										<span>$1.00</span>
									</div>
								</div>
							</div>
						</div>
					</FadeInSection>
				}
				<FadeInSection>
				<div className="card">
					<div className="column">
						<div className="row">
							<div className="title">
								<span>5</span>
								<span>
									<span>Win<span>OLS</span></span>
									<span>credits</span>
								</span>
							</div>
							<img className="logo" src={evc}/>
						</div>
						<div className="row">
							<Button
								type="button"
								name="buy"
								className="button outlined"
								label="buy"
								onClick={() => props.onBuy(5, 12000)}
							/>
							<div className="description">
								<span>$120.00</span>
								<span>$130.00</span>
							</div>
						</div>
					</div>
				</div>
                </FadeInSection>
                <FadeInSection timeout={1}>
				<div className="card">
					<div className="column">
						<div className="row">
							<div className="title">
								<span>10</span>
								<span>
									<span>Win<span>OLS</span></span>
									<span>credits</span>
								</span>
							</div>
							<img className="logo" src={evc}/>
						</div>
						<div className="row">
							<Button
								type="button"
								name="buy"
								className="button outlined"
								label="buy"
								onClick={() => props.onBuy(10, 22000)}
							/>
							<div className="description">
								<span>$220.00</span>
								<span>$260.00</span>
							</div>
						</div>
					</div>
				</div>
                </FadeInSection>
                <FadeInSection timeout={2}>
				<div className="card">
					<div className="column">
						<div className="row">
							<div className="title">
								<span>20</span>
								<span>
									<span>Win<span>OLS</span></span>
									<span>credits</span>
								</span>
							</div>
							<img className="logo" src={evc}/>
						</div>
						<div className="row">
							<Button
								type="button"
								name="buy"
								className="button outlined"
								label="buy"
								onClick={() => props.onBuy(20, 40000)}
							/>
							<div className="description">
								<span>$400.00</span>
								<span>$520.00</span>
							</div>
						</div>
					</div>
				</div>
                </FadeInSection>
                <FadeInSection timeout={3}>
				<div className="card">
					<div className="column">
						<div className="row">
							<div className="title">
								<span>50</span>
								<span>
									<span>Win<span>OLS</span></span>
									<span>credits</span>
								</span>
							</div>
							<img className="logo" src={evc}/>
						</div>
						<div className="row">
							<Button
								type="button"
								name="buy"
								className="button outlined"
								label="buy"
								onClick={() => props.onBuy(50, 90000)}
							/>
							<div className="description">
								<span>$900.00</span>
								<span>$1300.00</span>
							</div>
						</div>
					</div>
				</div>
                </FadeInSection>
			</div>
		</section>
	</div>
}

export default Credits;