import React from "react"
import Button from "./Button"
import Input from "./Input"
import Checkbox from "./Checkbox"

function WinOLSCustomerNumber(props) {
	const [formState, setFormState]  = React.useState({})
	const [formSubmitStatus, setFormSubmitStatus]  = React.useState()

	async function onSubmit(e) {
		e.preventDefault()

				const response = await fetch(
					"/winols_customer_number",
					{
						method: 'POST',
						mode: 'same-origin',
						cache: 'no-cache',
						credentials: 'same-origin',
						headers: {
							'Content-Type': 'application/json'
						},
						redirect: 'error',
						referrerPolicy: 'strict-origin',
						body: JSON.stringify({
							...formState
						})
					}
				)

				if (response && response.status === 200) {
					props.onWinOLSCustomerNumberStateChange(formState.winols_customer_number)
				} else {
					setFormSubmitStatus("failure")
				}

	}

	return <>
		<p>Provide your WinOLS customer number to be able to purchase WinOLS credits for Sweet Remaps.</p>
		<form onSubmit={onSubmit}>
			<Input
				name="winols_customer_number"
				type="text"
				label="WinOLS customer number"
				onChange={(value) => setFormState({ ...formState, winols_customer_number: value.trim() })}
			/>
			<Checkbox
				label="I confirm that the provided customer number belongs to me and contains no mistakes."
				value="winols_customer_number_confirmation"
				selected={!!formState.winols_customer_number_confirmation}
				onChange={(selected) => setFormState({ ...formState, winols_customer_number_confirmation: selected })}
			/>
			<Checkbox
				value="winols_customer_number_terms_confirmation"
				selected={!!formState.winols_customer_number_terms_confirmation}
				onChange={(selected) => setFormState({ ...formState, winols_customer_number_terms_confirmation: selected })}
			>
				<p>I understand and accept that:</p>
				<ul>
					<li>a WinOLS credits account will be created for the provided customer number if it doesn't exist; otherwise, the existing account will be used instead;</li>
					<li>the credits account will receive all WinOLS credits purchased by me, {props.userEmail}, through this website.</li>
				</ul>
			</Checkbox>
			{formSubmitStatus === "failure" && <div className="alert failure top">Oh no! Something went wrong, please try again later.</div>}
			<div className="controls">
				<Button type="button" name="cancel" className="button outlined" label="cancel" onClick={props.onCancel}/>
				<Button type="submit" name="continue" className="button" label={props.buttonLabel}/>
			</div>
		</form>
	</>
}

export default WinOLSCustomerNumber;