function Input(props) {
	return <div className="input">
		<label htmlFor={props.name}>{props.label}</label>
		{
			props.linkText && 
			props.onLinkClick &&
			<a onClick={props.onLinkClick}>{props.linkText}</a>
		}
		<input type={props.type} value={props.value} name={props.name} onChange={(e) => props.onChange && props.onChange(props.type === "file" ? e.target.files : e.target.value)}/>
	</div>
}

export default Input;