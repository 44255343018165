import { NavLink } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";

function SubMenuItem(props) {
	const location = useLocation();

	const getItemClass = React.useCallback(({ isActive }) => 
		 isActive || location.pathname === `${props.url}/` ? "active" : undefined
	, [props.subItemsPaths, location])

	return <li>
		<NavLink
			to={props.url}
			onClick={props.onClick}
			className={getItemClass}
			end
		>
			<span className="base"></span>
			<p>{props.text}</p>
			<span className="indicator"></span>
		</NavLink>
	</li>
}

export default SubMenuItem;