import React from "react"
import Button from "../components/Button"
import Input from "../components/Input"
import './Order.css';
import FadeInSection from "../components/FadeInSection";
import { Link } from "react-router-dom";
import { useTitle } from "../App";

function Order(props) {
	useTitle('Tuning order');

	const [formSubmitStatus, setFormSubmitStatus]  = React.useState()
	const [formState, setFormState]  = React.useState({})
	const [formRemapStages, setFormRemapStages]  = React.useState([
		{
			name: "1",
			value: "first",
			selected: true
		},
		{
			name: "2",
			value: "second",
			selected: false
		}
	])
	const [formEngineTypes, setFormEngineTypes]  = React.useState([
		{
			name: "Diesel",
			value: "diesel",
			selected: true
		},
		{
			name: "Petrol",
			value: "petrol",
			selected: false
		},
	])
	const [formSwitchOffs, setFormSwitchOffs]  = React.useState([
		{
			name: "DPF",
			value: "dpf",
			selected: false,
			engine: "diesel"
		},
		{
			name: "EGR",
			value: "egr",
			selected: false,
			engine: "diesel"
		},
		{
			name: "O2",
			value: "o2",
			selected: false
		},
		{
			name: "ADBLUE",
			value: "adblue",
			selected: false,
			engine: "diesel"
		},
		{
			name: "DTC",
			value: "dtc",
			selected: false
		}
	])
	const [formDtcList, setFormDtcList]  = React.useState([])
	const [formDtcInput, setFormDtcInput]  = React.useState("")
	const [formActivations, setFormActivations]  = React.useState([
		{
			name: "Crackle",
			value: "crackle",
			selected: false,
			engine: "petrol"
		},
		{
			name: "Popcorn",
			value: "popcorn",
			selected: false,
			engine: "diesel"
		}
	])
	const [formEcuFile, setFormEcuFile]  = React.useState()

	const dtcMatchRegex = /^(P{1}[0-9A-Z]{4}){1}$/
	const dtcFormatRegex = /^(P{1}[0-9A-Z]{0,4}){1}.*$/
	const dtcTemplate = "P"
	
	const onDtcInputChange = (value) => {
		const upCaseValue = value.toUpperCase()
		if (dtcMatchRegex.test(upCaseValue)) {
			!formDtcList.includes(upCaseValue) && setFormDtcList([...formDtcList, upCaseValue])
			setFormDtcInput(dtcTemplate)
		} else if (value === "") {
			setFormDtcInput(dtcTemplate)
		} else {
			setFormDtcInput(
			(dtcFormatRegex.test(upCaseValue) &&
				upCaseValue.replace(dtcFormatRegex, "$1")) ||
				dtcTemplate
			)
		}
	}

	const onFormSubmit = async () => {
				const selectedRemappingStage = formRemapStages.find(rs => rs.selected).value
				const selectedEngineType = formEngineTypes.find(rs => rs.selected).value
				const selectedSwitchOffs = formSwitchOffs
					.filter(so => so.selected && (!so.engine || so.engine === selectedEngineType))
					.map(so => so.value)
				const selectedDtc = selectedSwitchOffs.includes("dtc") ? formDtcList : []
				const selectedActivations = formActivations
					.filter(so => so.selected && (!so.engine || so.engine === selectedEngineType))
					.map(so => so.value)

				const formData = new FormData()
				Object.keys(formState).map(key => formData.append(key, formState[key]))
				formData.append("remapping_stage", selectedRemappingStage)
				formData.append("engine_type", selectedEngineType)
				selectedSwitchOffs.map(so => formData.append("switch_offs[]", so))
				selectedDtc.map(c => formData.append("dtc[]", c))
				selectedActivations.map(a => formData.append("activations[]", a))
				formData.append("ecu_file", formEcuFile)

				const response = await fetch(
					"/tuning_order",
					{
						method: 'POST',
						mode: 'same-origin',
						cache: 'no-cache',
						credentials: 'same-origin',
						redirect: 'error',
						referrerPolicy: 'strict-origin',
						body: formData
					}
				)

				const { id, amount } = await response.json()

				if (response && response.status === 200) {
					setFormState({})
					setFormEcuFile()
					setFormRemapStages(formRemapStages.map(rs => rs.value === "first" ? { ...rs, selected: true } : { ...rs, selected: false }))
					setFormEngineTypes(formEngineTypes.map(et => et.value === "diesel" ? { ...et, selected: true } : { ...et, selected: false }))
					setFormSwitchOffs(formSwitchOffs.map(so => ({ ...so, selected: false })))
					setFormDtcList([])
					setFormActivations(formActivations.map(a => ({ ...a, selected: false })))
					setFormSubmitStatus("success")
					props.onOrder(id, amount)
				} else {
					setFormSubmitStatus("failure")
				}
	}

	const selectedEngine = formEngineTypes.find(e => e.selected)
	const selectedStage = formRemapStages.find(rs => rs.selected)
	const dtcSelected = formSwitchOffs.find(s => s.value === "dtc").selected

	return (
		<div className="main content">
			<section>
		<FadeInSection>
          <div className="box">
          <span className="indicator"></span>			
		  	<p className="content">
				Submit an order bellow to get a <em>custom tune</em> ready within one <Link to="/contact#working_hours">working hour</Link>.
			</p>
          </div>
        </FadeInSection>
			<div className="flex center reverse">
				<div className="item">
				<FadeInSection>
					<form 
						onSubmit={(e) => {
							e.preventDefault()
							onFormSubmit()
						}
					}>
						<Input 
							name="vehicle_type"
							type="text"
							label="Vehicle type *"
							value={formState.vehicle_type || ""}
							onChange={(value) => setFormState({ ...formState, vehicle_type: value })}
						/>
						<Input 
							name="vehicle_brand"
							type="text"
							label="Brand *"
							value={formState.vehicle_brand || ""}
							onChange={(value) => setFormState({ ...formState, vehicle_brand: value })}
						/>
						<Input 
							name="vehicle_model"
							type="text"
							label="Model *"
							value={formState.vehicle_model || ""}
							onChange={(value) => setFormState({ ...formState, vehicle_model: value })}
						/>
						<Input 
							name="engine_code"
							type="text"
							label="Engine code *"
							value={formState.engine_code || ""}
							onChange={(value) => setFormState({ ...formState, engine_code: value })}
						/>
						<Input 
							name="ecu_type"
							type="text"
							label="ECU type *"
							value={formState.ecu_type || ""}
							onChange={(value) => setFormState({ ...formState, ecu_type: value })}
						/>
						<div className="input">
							<fieldset>
								<legend>Remapping stage *</legend>
								<ul className="horizontal">
									{formRemapStages.map((stage, i) =>
										<React.Fragment key={stage.value}>
											<li
												tabIndex="0"
												key={stage.value}
												className={"radio " + (stage.selected ? "selected" : "")}
												onKeyDown={(e) => 
													e.key === 'Enter' && setFormRemapStages(formRemapStages.map(s => 
														s.value === stage.value ? {...s, selected: true} : {...s, selected: false}
													))
												}
												onClick={(_e) => 
													setFormRemapStages(formRemapStages.map(s => 
														s.value === stage.value ? {...s, selected: true} : {...s, selected: false}
													))
												}
											>
												<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 9}}>
													<circle cx="8.5" cy="8.5" r="8" fill="#0E0E0E" stroke="#525252" />
													{stage.selected && <circle cx="8.5" cy="8.5" r="4" fill="#FAFAFA" stroke="#FAFAFA" />}
												</svg>
												<input tabIndex="-1" type="radio" id={stage.value} value={stage.value} checked={stage.selected} onChange={(_e) => {}}  hidden/>
												<label htmlFor={stage.value} onClick={(e) => e.preventDefault()}>{stage.name}</label>
											</li>
										</React.Fragment>
									)}
								</ul>
							</fieldset>
						</div>
						<div className="input">
							<fieldset>
								<legend>Engine type *</legend>
								<ul className="horizontal">
									{formEngineTypes.map(engine => {
										return (
											<li 
												tabIndex="0"
												key={engine.value}
												className={"radio " + (engine.selected ? "selected" : "")}
												onKeyDown={(e) => 
													e.key === 'Enter' && setFormEngineTypes(formEngineTypes.map(et => 
														et.value === engine.value ? {...et, selected: true} : {...et, selected: false} 
													))
												}
												onClick={(_e) => 
													setFormEngineTypes(formEngineTypes.map(et => 
														et.value === engine.value ? {...et, selected: true} : {...et, selected: false} 
													))
												}
											>
												<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 9}}>
													<circle cx="8.5" cy="8.5" r="8" fill="#0E0E0E" stroke="#525252" />
													{engine.selected && <circle cx="8.5" cy="8.5" r="4" fill="#FAFAFA" stroke="#FAFAFA" />}
												</svg>
												<input tabIndex="-1" type="radio" id={engine.value} value={engine.value} checked={engine.selected} onChange={(_e) => {}}  hidden/>
												<label htmlFor={engine.value} onClick={(e) => e.preventDefault()}>{engine.name}</label>
											</li>
										)
									})}
								</ul>
							</fieldset>
						</div>
						<div className="input">
							<fieldset>
								<legend>Switch off</legend>
								<ul className="horizontal">
									{formSwitchOffs.filter(s => !(s.engine && selectedEngine && s.engine !== selectedEngine.value)).map(switchOff => {
										return (
											<li 
												key={switchOff.value}
												tabIndex="0"
												className={"checkbox " + (switchOff.selected ? "selected" : "")}
												onKeyDown={(e) => 
													e.key === 'Enter' && setFormSwitchOffs(formSwitchOffs.map(s => 
														s.value === switchOff.value ? {...s, selected: !s.selected} : s 
													))
												}
												onClick={(_e) => 
													setFormSwitchOffs(formSwitchOffs.map(s => 
														s.value === switchOff.value ? {...s, selected: !s.selected} : s 
													))
												}
											>
												<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 9}}>
													<rect x="0.5" y="0.5" width="16" height="16" fill="#0E0E0E" stroke="#525252" rx="2"/>
													{switchOff.selected && <rect x="4.5" y="4.5" width="8" height="8" fill="#FAFAFA" stroke="FAFAFA" rx="2"/>}
												</svg>
												<input tabIndex="-1" type="checkbox" id={switchOff.value} value={switchOff.value} checked={switchOff.selected} onChange={(_e) => {}}  hidden/>
												<label htmlFor={switchOff.value} onClick={(e) => e.preventDefault()}>{switchOff.name}</label>
											</li>
										)
									})}
								</ul>
								{dtcSelected && <>
									<label>Diagnostic trouble codes (P-DTC):</label>
									<ul className="horizontal">
										{formDtcList.map(dtc => {
											return (
												<li 
													tabIndex="0"
													key={dtc}
													className={"selected"}
													onKeyDown={(e) => 
														e.key === 'Enter' && setFormDtcList(formDtcList.filter(d => d !== dtc))
													}
													onClick={(_e) => 
														setFormDtcList(formDtcList.filter(d => d !== dtc))
													}
												>
													<label>{dtc}</label>
												</li>
											)
										})}
										<li className="editable">
											<input
												tabIndex="0"
												className="dtc"
												type="text"
												placeholder="P"
												value={formDtcInput}
												name="dtc_code"
												onFocus={() => setFormDtcInput("P")}
												onBlur={() => setFormDtcInput("")}
												onChange={(e) => onDtcInputChange(e.target.value)}
												onKeyDown={(e) => {
													if(e.key === 'Enter') {
														e.preventDefault()
														e.stopPropagation()
														onDtcInputChange(e.target.value)
													}
												}}
											/>
										</li>
									</ul>
								</>}
							</fieldset>
						</div>
						<div className="input">
							<fieldset>
								<legend>Activate</legend>
								<ul className="horizontal">
									{formActivations.filter(a => !(a.engine && selectedEngine && a.engine !== selectedEngine.value)).map(activation => {
										return (
											<li 
												tabIndex="0"
												key={activation.value}
												className={"checkbox " + (activation.selected ? "selected" : "")}
												onKeyDown={(e) => 
													e.key === 'Enter' && setFormActivations(formActivations.map(s => 
														s.value === activation.value ? {...s, selected: !s.selected} : s 
													))
												}
												onClick={(_e) => 
													setFormActivations(formActivations.map(s => 
														s.value === activation.value ? {...s, selected: !s.selected} : s 
													))
												}
											>
												<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 9}}>
													<rect x="0.5" y="0.5" width="16" height="16" fill="#0E0E0E" stroke="#525252" rx="2"/>
													{activation.selected && <rect x="4.5" y="4.5" width="8" height="8" fill="#FAFAFA" stroke="FAFAFA" rx="2"/>}
												</svg>
												<input tabIndex="-1" type="checkbox" id={activation.value} value={activation.value} checked={activation.selected} onChange={(_e) => {}}  hidden/>
												<label htmlFor={activation.value} onClick={(e) => e.preventDefault()}>{activation.name}</label>
											</li>
										)
									})}
								</ul>
							</fieldset>
						</div>
						<div className="input">
							<label htmlFor="ecu_file" onClick={(e) => e.preventDefault()}>ECU file *</label>
							<input type="file" id="ecu_file" name="ecu_file" value={formEcuFile ? undefined : ""} onChange={(e) => setFormEcuFile(e.target.files[0])} tabIndex="-1"/>
							<div className="file-browser-container">
								<input type="button" id="ecu_file_browse" value="browse" className="browse" onClick={(e) => e.target.parentElement.parentElement.querySelector("input[type=file]").click()}/>
								<label className="file">
									{formEcuFile ? formEcuFile.name : <span className="placeholder">No file selected</span>}
								</label>
							</div>
						</div>
						{formSubmitStatus === "success" && <div className="alert success top"><p>Sent! You can see the order's current status in your <Link to="/account/orders">account</Link>.</p></div>}
						{formSubmitStatus === "failure" && <div className="alert failure top"><p>Oh no! Something went wrong. If the issue persists, don't hesitate to <Link to="/contact">contact us</Link>.</p><p><b>Tip:</b> Please make sure that all required fields marked with "*" are filled.</p></div>}
						{props.sessionState 
							? <Button type="submit" name="tuning_order" className="button" label="order and pay"/>
							: <Button
								type="button"
								className="button outlined"
								name="sign in"
								label="sign in to order"
								onClick={props.onSignIn}
							/>
						}
					</form>
					</FadeInSection>
				</div>
				<div className="item sticky" style={{ flexBasis: '30%' }}>
					<FadeInSection>
						<div className="box">
							<section className="content">
								<p>
									<span className="working-hours-box">
										<span className="working-hours-row"><span><em>Total price:</em></span><span><b>{selectedStage.value === 'first' ? 350 : 400} <span className="small-up">AUD</span></b></span></span>
									</span>
								</p>
							</section>
						</div>
					</FadeInSection>
				</div>
			</div>
			</section>
		</div>
	)
}


export default Order;