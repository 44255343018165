import React from "react"
import { Link } from "react-router-dom"
import Button from "./Button"
import Checkbox from "./Checkbox"
import Input from "./Input"

function SignUp(props) {
	const [formState, setFormState] = React.useState({})
	const [formSubmitStatus, setFormSubmitStatus] = React.useState()

	async function onSubmit(e) {
		e.preventDefault()

		const response = await fetch(
			"/sign_up",
			{
				method: 'POST',
				mode: 'same-origin',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json'
				},
				redirect: 'error',
				referrerPolicy: 'strict-origin',
				body: JSON.stringify({
					...formState
				})
			}
		)

		if (response && response.status === 200) {
			props.onSessionStateChange(true)
			props.onAccountStateChange(false)
			props.onWinOLSCustomerNumberStateChange(undefined)
			props.onUserEmailChange(formState.email)
		} else {
			setFormSubmitStatus("failure")
		}
	}

	return <>
		<form onSubmit={onSubmit}>
			<Input
				name="email"
				type="email"
				label="Email"
				onChange={(value) => setFormState({ ...formState, email: value })}
			/>
			<Input
				name="password"
				type="password"
				label="Password"
				onChange={(value) => setFormState({ ...formState, password: value })}
			/>
			<Checkbox
				label="I checked my password and it contains no mistypes."
				value="password_confirmation"
				selected={!!formState.password_confirmation}
				onChange={(selected) => setFormState({ ...formState, password_confirmation: selected })}
			/>
			{formSubmitStatus === "failure" && <div className="alert failure top"><p>Oh no! Something went wrong. If the issue persists, don't hesitate to <Link to="/contact">contact us</Link>.</p><p><b>Tip:</b> Please make sure that your email address is correct and your password is 8 characters or longer.</p></div>}
			<div className="controls">
				<Button type="button" name="cancel" className="button outlined" label="cancel" onClick={props.onCancel}/>
				<Button type="submit" name="sign_up" className="button" label={props.buttonLabel || "sign up"}/>
			</div>
		</form>
		<p>Already have an account? <a onClick={props.onSignIn}>Sign in</a>.</p>
	</>
}

export default SignUp;