import logo from './logo.svg';
import './App.css';
import Main from './pages/Main';
import Credits from './pages/Credits';
import React from 'react';
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MenuItem from './components/MenuItem';
import SubMenuItem from './components/SubMenuItem';
import Button from './components/Button';
import Order from './pages/Order';
import Contact from './pages/Contact';
import Dialog from './components/Dialog';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import VerifyAccount from './components/VerifyAccount';
import WinOLSCustomerNumber from './components/WinOLSCustomerNumber';
import Checkout from './components/Checkout';
import SignOut from './components/SignOut';
import ForgotPassword from './components/ForgotPassword';
import PasswordReset from './components/PasswordReset';
import Account from './pages/Account';
import cookies from "js-cookies";
import Orders from './pages/Orders';
import OrderCheckout from './components/OrderCheckout';
import ReactGA from 'react-ga';

export function useTitle(title) {
  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = title;

    return () => {
      document.title = prevTitle;
    };
  }, []);
}

function App(props) {
  const [sessionState, setSessionState] = React.useState(cookies.getItem("status") === "in")
	const [accountState, setAccountState] = React.useState()
	const [winOLSCustomerNumberState, setWinOLSCustomerNumberState] = React.useState()
  const [userEmail, setUserEmail] = React.useState()
  const [flow, setFlow] = React.useState()
	const [flowStep, setFlowStep] = React.useState()
	const [basketState, setBasketState] = React.useState()
  const [forceVisibleMenu, setForceVisibleMenu] = React.useState(false)
	const [overlayState, setOverlayState]  = React.useState()

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS === 'true') {
      ReactGA.initialize('UA-230071586-1');
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    if (process.env.REACT_APP_GOOGLE_ANALYTICS === 'true') {
      ReactGA.pageview(location.pathname)
    }
  }, [location]);

  React.useEffect(() => {
		(async function() {
      const response = await fetch(
        `/data/user`,
        {
          method: 'GET',
          mode: 'same-origin',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'error',
          referrerPolicy: 'strict-origin'
        }
      )
		
			if (!response || !(response.status === 200 || response.status === 401)) {
				console.error(response)
        return
			}

      if (response.status === 401) {
        setSessionState(false);
        setAccountState(undefined);
        setWinOLSCustomerNumberState(undefined);
        setUserEmail(undefined);
        
        return
      }

			const { account } = await response.json()

      setSessionState(true);
      setAccountState(account.verified);
      setWinOLSCustomerNumberState(account.winolsCustomerNumber);
      setUserEmail(account.email);
		})()
  }, [setSessionState, setAccountState, setUserEmail, setWinOLSCustomerNumberState])


  React.useEffect(() => {
    if (flow?.type === 'sign_in') {
      if (!sessionState) {
        setFlowStep({
          type: "sign_in",
          title: "Sign in",
          actionLabel: "sign in"
        })

        return
      }

      setFlowStep(undefined)
      setFlow(undefined)
    }
  }, [sessionState, flow, setFlow, setFlowStep])

  React.useEffect(() => {
    if (flow?.type === 'sign_out') {
      if (sessionState) {
        setFlowStep({
          type: "sign_out",
          title: "Sign out",
          actionLabel: "sign out"
        })

        return
      }

      setFlowStep(undefined)
      setFlow(undefined)
    }
  }, [sessionState, flow, setFlow, setFlowStep])

  React.useEffect(() => {
    if (flow?.type === 'forgot_password') {
      setFlowStep({
        type: "forgot_password",
        title: "Request password reset",
        actionLabel: "request",
        props: {
          userEmail: flow.data.email
        }
      })
    }
  }, [sessionState, flow, setFlow, setFlowStep])

  React.useEffect(() => {
    if (flow?.type === 'sign_up') {
      if (!sessionState) {
        setFlowStep({
          type: "sign_up",
          title: "Sign up",
          actionLabel: "sign up"
        })

        return
      }

      if (!accountState) {
        setFlowStep({
          type: "verify_account",
          title: "Verify your account",
          actionLabel: "verify"
        })

        return
      }

      setFlowStep(undefined)
      setFlow(undefined)
    }
  }, [sessionState, accountState, flow, setFlow, setFlowStep])

  React.useEffect(() => {
    if (flow?.type === 'checkout' || flow?.type === "checkout_sign_up") {
      if (!sessionState) {
        if (flow?.type === "checkout_sign_up") {
          setFlowStep({
            type: "sign_up",
            title: "Sign up to purchase credits",
            actionLabel: "sign up and checkout"
          })
        } else {
          setFlowStep({
            type: "sign_in",
            title: "Sign in to purchase credits",
            actionLabel: "sign in and checkout"
          })
        }

        return
      }

      if (!accountState) {
        setFlowStep({
          type: "verify_account",
          title: "Verify your account",
          actionLabel: "vertify"
        })

        return
      }

      if (!winOLSCustomerNumberState) {
        setFlowStep({
          type: "winols_customer",
          title: "Become our WinOLS customer",
          actionLabel: "continue"
        })

        return
      }

      setFlowStep({
        type: "checkout",
        title: "Purchase WinOLS credits",
        actionLabel: "checkout"
      })
    }
  }, [sessionState, accountState, winOLSCustomerNumberState, flow, setFlow, setFlowStep])

  React.useEffect(() => {
    if (flow?.type === 'order_checkout') {
      setFlowStep({
        type: "order_checkout",
        title: "Checkout to continue",
        actionLabel: "checkout",
        props: {
          orderId: flow.data.orderId,
          amount: flow.data.amount
        }
      })
    }
  }, [sessionState, accountState, winOLSCustomerNumberState, flow, setFlow, setFlowStep])

  const handleSignIn = React.useCallback(() => 
    setFlow({
      type: "sign_in"
    })
  , [setFlow])

  const handleSignOut = React.useCallback(() => 
    setFlow({
      type: "sign_out"
    })
  , [setFlow])

  const handleForgotPassword = React.useCallback((email) => 
    setFlow({
      type: "forgot_password",
      data: {
        email
      }
    })
  , [setFlow])

  const handleSignUp = React.useCallback(() => 
    setFlow({
      type: flow?.type === "checkout" ? "checkout_sign_up" : "sign_up"
    })
  , [flow, setFlow])

  const handleCheckout = React.useCallback((amount,  cost) => {
		setBasketState({
			amountOfWinOLSCredits: amount,
			totalCost: cost
		})
    setFlow({
      type: "checkout"
    })
  }, [setBasketState, setFlow])

  const handleOrder = React.useCallback((id, amount) => {
    setFlow({
      type: "order_checkout",
      data: {
        orderId: id,
        amount: amount
      }
    })
  }, [setFlow])

  const handleCancelFlow = React.useCallback(() => {
    setFlowStep(undefined)
    setFlow(undefined)
  }, [setFlow, setFlowStep])

  const handleCancelCheckoutFlow = React.useCallback(() => {
    setFlowStep(undefined)
    setFlow(undefined)
    setBasketState(undefined)
  }, [setFlow, setFlowStep, setBasketState])

  const handleCloseMenuOverlay = React.useCallback(() => {
		if (overlayState) {
			overlayState.onClose()
			setOverlayState(undefined)
		}
	}, [overlayState, setOverlayState]) 

  return (
    <div className="App">
      <header className="App-header">
        <Link to="/"><img src={logo} className="App-logo" alt="logo" /></Link>
        {!forceVisibleMenu && <div 
          className="menuIcon" 
          onClick={() => {
            setForceVisibleMenu(true)
            setOverlayState({
              className: 'menu',
              onClose: () => setForceVisibleMenu(false)
            })
          }}
        >
					menu
				</div>}
        <div className="navigation-box">
          {forceVisibleMenu && <div className="overlay menu" onClick={handleCloseMenuOverlay}></div>}
		      <nav style={ forceVisibleMenu ? { display: 'inline-flex' } : undefined }>
            <ul>
              <MenuItem onClick={handleCloseMenuOverlay} url="/tuning_order" text="tuning order"/>
              <MenuItem onClick={handleCloseMenuOverlay} url="/winols/credits" text="winols"/>
				      <MenuItem onClick={handleCloseMenuOverlay} url="/contact" text="contact"/>
              {sessionState && <MenuItem 
                onClick={handleCloseMenuOverlay} 
                exact={false} 
                url="/account/orders"
                subItemsPaths={["/account/settings", "/account/orders"]}
                text="account" 
                subItems={
                  <ul>
                    <SubMenuItem url="/account/orders" text="orders" onClick={handleCloseMenuOverlay}/>
                    <SubMenuItem url="/account/settings" text="settings" onClick={handleCloseMenuOverlay}/>
                  </ul>
                }/>}
            </ul>
          </nav>
          {!sessionState && <Button
            type="button"
            className="button outlined"
            name="sign in"
            label="sign in"
            onClick={handleSignIn}
          />}
        </div>
      </header>
      <Routes>
        <Route
          path="/"
          element={<Main
            sessionState={sessionState}
            flow={flow}
            setFlow={setFlow}
            setFlowStep={setFlowStep}
          />}
          exact
        >
          <Route
            path="password_reset"
            exact
          />
        </Route>
        <Route
          path="/winols/credits"
          element={
            <Credits
              userEmail={userEmail}
              sessionState={sessionState}
              accountState={accountState}
              winOLSCustomerNumber={winOLSCustomerNumberState}
              basketState={basketState}
              paypalCurrencyCode={props.paypalCurrencyCode}
              paypalClientId={props.paypalClientId}
              onBuy={handleCheckout}
            />
          }
        />
        <Route
          path="/tuning_order"
          element={<Order onOrder={handleOrder} onSignIn={handleSignIn} sessionState={sessionState} />}
        />
        {<Route
          path="/account/settings"
          element={sessionState ? <Account userEmail={userEmail} onSignOut={handleSignOut}/> : <Navigate to="/" replace />}
        />}
        {<Route
          path="/account/orders"
          element={sessionState ? <Orders onPay={handleOrder}/> : <Navigate to="/" replace />}
        />}
        <Route
          path="/contact"
          element={<Contact userEmail={userEmail}/>}
        />
      </Routes>
      { flowStep?.type === "sign_out" && <Dialog title={flowStep.title} onClose={handleCancelFlow}>
				<SignOut
          buttonLabel={flowStep.actionLabel}
					onSessionStateChange={setSessionState}
					onAccountStateChange={setAccountState}
					onWinOLSCustomerNumberStateChange={setWinOLSCustomerNumberState}
          onUserEmailChange={setUserEmail}
					onCancel={handleCancelFlow}
				/>
			</Dialog> }
      { flowStep?.type === "sign_in" && <Dialog title={flowStep.title} onClose={handleCancelFlow}>
				<SignIn 
          buttonLabel={flowStep.actionLabel}
					onSessionStateChange={setSessionState}
					onAccountStateChange={setAccountState}
					onWinOLSCustomerNumberStateChange={setWinOLSCustomerNumberState}
          onUserEmailChange={setUserEmail}
          onSignUp={handleSignUp}
          onForgotPassword={handleForgotPassword}
					onCancel={handleCancelFlow}
				/>
			</Dialog> }
			{ flowStep?.type === "sign_up" && <Dialog title={flowStep.title} onClose={handleCancelFlow}>
				<SignUp 
          buttonLabel={flowStep.actionLabel}
					onSessionStateChange={setSessionState}
					onAccountStateChange={setAccountState}
					onWinOLSCustomerNumberStateChange={setWinOLSCustomerNumberState}
          onUserEmailChange={setUserEmail}
					onSignIn={handleSignIn}
					onCancel={handleCancelFlow}
				/>
			</Dialog> }
      { flowStep?.type === "forgot_password" && <Dialog title={flowStep.title} onClose={handleCancelFlow}>
				<ForgotPassword
          buttonLabel={flowStep.actionLabel}
					onAccountStateChange={setAccountState}
          userEmail={userEmail}
					onCancel={handleCancelFlow}
          onSignIn={handleSignIn}
          {...flowStep.props}
				/>
			</Dialog> }
      { flowStep?.type === "password_reset" && <Dialog title={flowStep.title} onClose={handleCancelFlow}>
				<PasswordReset
          buttonLabel={flowStep.actionLabel}
					onSessionStateChange={setSessionState}
					onAccountStateChange={setAccountState}
					onWinOLSCustomerNumberStateChange={setWinOLSCustomerNumberState}
          onUserEmailChange={setUserEmail}
					onCancel={(e) => { handleCancelFlow(e); navigate("/", { replace: true }); }}
          onSignIn={(e) => { handleSignIn(e); navigate("/", { replace: true }); }}
				/>
			</Dialog> }
			{ flowStep?.type === "verify_account" && <Dialog title={flowStep.title} onClose={handleCancelFlow}>
				<VerifyAccount
          buttonLabel={flowStep.actionLabel}
					onAccountStateChange={setAccountState}
					onCancel={handleCancelFlow}
          userEmail={userEmail}
				/>
			</Dialog> }
			{ flowStep?.type === "winols_customer" && <Dialog title={flowStep.title} onClose={handleCancelFlow}>
				<WinOLSCustomerNumber
          userEmail={userEmail}
          buttonLabel={flowStep.actionLabel}
					onWinOLSCustomerNumberStateChange={setWinOLSCustomerNumberState}
					onCancel={handleCancelFlow}
				/>
			</Dialog> }
			{ flowStep?.type === "checkout" && <Dialog title={flowStep.title} onClose={handleCancelCheckoutFlow}>
        <Checkout
          buttonLabel={flowStep.actionLabel}
					basketState={basketState}
					winOLSCustomerNumberState={winOLSCustomerNumberState}
          paypalMerchantEmail={props.paypalMerchantEmail}
          paypalMerchantId={props.paypalMerchantId}
          paypalCurrencyCode={props.paypalCurrencyCode}
          paypalClientId={props.paypalClientId}
          onCancel={handleCancelCheckoutFlow}
				/>
			</Dialog> }
      { flowStep?.type === "order_checkout" && <Dialog title={flowStep.title} onClose={handleCancelCheckoutFlow}>
        <OrderCheckout
          buttonLabel={flowStep.actionLabel}
					basketState={basketState}
					winOLSCustomerNumberState={winOLSCustomerNumberState}
          paypalMerchantEmail={props.paypalMerchantEmail}
          paypalMerchantId={props.paypalMerchantId}
          paypalCurrencyCode={props.paypalCurrencyCode}
          paypalClientId={props.paypalClientId}
          onCancel={handleCancelCheckoutFlow}
          userEmail={userEmail}
          {...flowStep.props}
				/>
			</Dialog> }
    </div>
  );
}

export default App;
