import React from "react"
import Button from "./Button"
import Input from "./Input"
import { Link } from "react-router-dom";

function VerifyAccount(props) {
	const [formState, setFormState]  = React.useState({})
	const [formSubmitStatus, setFormSubmitStatus]  = React.useState()

	async function onSubmit(e) {
		e.preventDefault()


				const response = await fetch(
					"/verify_account",
					{
						method: 'POST',
						mode: 'same-origin',
						cache: 'no-cache',
						credentials: 'same-origin',
						headers: {
							'Content-Type': 'application/json'
						},
						redirect: 'error',
						referrerPolicy: 'strict-origin',
						body: JSON.stringify({
							...formState
						})
					}
				)

				if (response && response.status === 200) {
					props.onAccountStateChange(true)
				} else {
					setFormSubmitStatus("failure")
				}
	}

	return <>
		<p>We sent a verification code to <b>{props.userEmail}</b>.</p>
		<form onSubmit={onSubmit}>
			<Input
				name="verification_code"
				type="text"
				label="Verification code"
				onChange={(value) => setFormState({ ...formState, verification_code: value.trim() })}
			/>
			{formSubmitStatus === "failure" && <div className="alert failure top"><p>Oh no! Something went wrong. If the issue persists, don't hesitate to <Link to="/contact">contact us</Link>.</p></div>}
			<div className="controls">
				<Button type="button" name="cancel" className="button outlined" label="cancel" onClick={props.onCancel}/>
				<Button type="submit" name="continue" className="button" label={props.buttonLabel}/>
			</div>
		</form>
		<p>If the code didn't arrive withing several minutes:</p>
		<ul>
			<li>check your spam folder;</li>
			<li>check that the email address is correct; if not, <a>update it</a>;</li>
			<li><Link onClick={props.onCancel} to="/contact">contact us</Link> and our team will be on it shortly.</li>
		</ul>
	</>
}

export default VerifyAccount;