import React from "react"
import Button from "./Button"
import Input from "./Input"

function SignIn(props) {
	const [formState, setFormState]  = React.useState({})
	const [formSubmitStatus, setFormSubmitStatus]  = React.useState()

	async function onSubmit(e) {
		e.preventDefault()

		const response = await fetch(
			"/sign_in",
			{
				method: 'POST',
				mode: 'same-origin',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json'
				},
				redirect: 'error',
				referrerPolicy: 'strict-origin',
				body: JSON.stringify({
					...formState
				})
			}
		);

		if (response && response.status === 200) {
			const { account } = await response.json()
			props.onSessionStateChange(true)
			props.onAccountStateChange(account.verified)
			props.onWinOLSCustomerNumberStateChange(account.winolsCustomerNumber)
			props.onUserEmailChange(account.email)
		} else {
			setFormSubmitStatus("failure")
		}
	};


	return <>
		<form onSubmit={onSubmit}>
			<Input 
				name="email"
				type="email"
				label="Email"
				onChange={(value) => setFormState({ ...formState, email: value })}
			/>
			<Input 
				name="password"
				type="password"
				label="Password"
				linkText="Forgot password?"
				onLinkClick={() => props.onForgotPassword(formState.email)}
				onChange={(value) => setFormState({ ...formState, password: value })}
			/>
			{formSubmitStatus === "failure" && <div className="alert failure top">Oh no! Something went wrong, please make sure your credentials are correct.</div>}
			<div className="controls">
				<Button type="button" name="cancel" className="button outlined" label="cancel" onClick={props.onCancel}/>
				<Button type="submit" name="sign_in" className="button outlined" label={props.buttonLabel || "sign in"}/>
			</div>
		</form>
		<p>Don't have an account? <a onClick={props.onSignUp}>Sign up</a></p>
	</>
}

export default SignIn;