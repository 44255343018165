import React from "react"
import { useTitle } from "../App";
import FadeInSection from "../components/FadeInSection"

function Orders(props) {
	useTitle('Account orders');

	const [orders, setOrders]  = React.useState([])

	React.useEffect(() => {
		const { promise, cancel } = cancelable(fetchOrders)
		promise
			.then((orders) => {
				console.log("orders fetched", orders)
				setOrders(orders)
			})
			.catch((_reason) => console.log("Request rejected"))
		return () => cancel()
	}, [])

	return  <div className="main content">
		<section>
			<FadeInSection>
				<h3 data-animation="Orders">Orders</h3>
				<div class="Rtable">
					<div className="Rtable-raw">
						<div className="Rtable-cell Rtable-cell--10 Rtable-cell--head">ID</div>
						<div className="Rtable-cell Rtable-cell--25">Created</div>
						<div className="Rtable-cell Rtable-cell--40">Vehicle</div>
						<div className="Rtable-cell Rtable-cell--20">Status</div>
						<div className="Rtable-cell Rtable-cell--10 Rtable-cell--foot "></div>
					</div>

					{orders.map(order => {
						const info = [order.vehicleType, order.vehicleBrand, order.vehicleModel, order.engineCode, order.ecuType].join(" • ")
						return (
							<div className="Rtable-raw">
								<div className="Rtable-cell Rtable-cell--10 Rtable-cell--head">{order.id}</div>
								<div className="Rtable-cell Rtable-cell--25">{order.createdDateTime}</div>
								<div className="Rtable-cell Rtable-cell--40">{info}</div>
								<div className={`Rtable-cell Rtable-cell--20 ${getStatusClass(order.status)}`}>{getStatusText(order.status)}</div>
								<div className="Rtable-cell Rtable-cell--10 Rtable-cell--foot"><OrderAction order={order} onPay={() => props.onPay(order.id, order.amount)}/></div>
							</div>
						)
					})}
				</div>
			</FadeInSection>
		</section>
	</div>
}

function fetchOrders() {
	return fetch(
		"/data/tuning_orders",
		{
			method: 'GET',
			mode: 'same-origin',
			cache: 'no-cache',
			credentials: 'same-origin',
			redirect: 'error',
			referrerPolicy: 'strict-origin'
		}
	).then(response => response.json())
}

function cancelable(request) {
	let canceled = false
  
	const promise = new Promise((resolve, reject) => {
		request().then(
			response => canceled ? reject({isCanceled: true}) : resolve(response),
			error => canceled ? reject({isCanceled: true}) : reject(error)
		)
	})
  
	return {
	  promise: promise,
	  cancel: () => canceled = true
	}
}

function OrderAction(props) {
	switch (props.order.status) {
		case "unpaid":
			return (
				<a onClick={props.onPay}>Pay</a>
			)
		case "paid":
			return "..."
		case "completed":
			return "..."
		default:
			return
	}
}

function getStatusClass(status) {
	switch (status) {
		case "unpaid":
			return "attention"
		case "paid":
			return "info"
		case "completed":
			return "success"
		default:
			return ""
	}
}

function getStatusText(status) {
	switch (status) {
		case "unpaid":
			return "Awaiting payment"
		case "paid":
			return "Processing"
		case "completed":
			return "Completed"
		default:
			return ""
	}
}

export default Orders;