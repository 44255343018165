import React from "react"
import FadeInSection from "./FadeInSection";

function Dialog(props) {
	return <div className="overlay dialog">
		<div className="inner" onClick={props.onClose}></div>
        <div className="content">
        <div className="wrapper">
        <FadeInSection>
            <h3 data-animation={props.title}>{props.title}</h3>
        
          {props.children}
          </FadeInSection>
        </div>
      </div>
	</div>
}

export default Dialog;