import Input from "../components/Input"
import Button from "../components/Button"
import React from "react"
import FadeInSection from "../components/FadeInSection"
import { useTitle } from "../App";

function Contact(props) {
	useTitle('Contact');

	const [formState, setFormState]  = React.useState({})
	const [formSubmitStatus, setFormSubmitStatus]  = React.useState()

	async function onSubmit(e) {
		e.preventDefault()

				const response = await fetch(
					"/send_message",
					{
						method: 'POST',
						mode: 'same-origin',
						cache: 'no-cache',
						credentials: 'same-origin',
						headers: {
							'Content-Type': 'application/json'
						},
						redirect: 'error',
						referrerPolicy: 'strict-origin',
						body: JSON.stringify({
							...formState
						})
					}
				)

				if (response && response.status === 200) {
					setFormState({})
					setFormSubmitStatus("success")
				} else {
					setFormSubmitStatus("failure")
				}
	}

	return <div className="main content">
					<section>	
		<FadeInSection>
			<div className="box">
			<span className="indicator"></span>		
				<section className="content">
					<h3 id="working_hours" data-animation="Working hours">Working hours</h3>
					<p>
						<span className="working-hours-box">
							<span className="working-hours-row"><span><em>Mon - Fri:</em></span><span><b>09:00 <span className="small-up">a.m.</span> - 05:00 <span className="small-up">p.m.</span></b></span></span>
							<span className="working-hours-row"><span><em>Sat - Sun:</em></span><span style={{ paddingRight: 5 }}><b>closed</b></span></span>
						</span>
					</p>
					<p><a href="https://time.is/GMT+10">AEST (GMT+10)</a></p>
				</section>	
			</div>
		</FadeInSection>
		<FadeInSection>
          <div className="box">
          <span className="indicator"></span>			
            <p className="content">
				If you have <em>questions or feedback</em>, please send us a message bellow.
			</p>
          </div>
        </FadeInSection>
		<FadeInSection>
			<div className="flex" style={{ justifyContent: 'center' }}>
				<div className="item">
					<form onSubmit={onSubmit}>
						<Input 
							name="name"
							type="text"
							label="Name"
							value={formState.name || ""}
							onChange={(value) => setFormState({ ...formState, name: value })}
						/>
						<Input 
							name="business_name"
							type="text"
							label="Business name"
							value={formState.business_name || ""}
							onChange={(value) => setFormState({ ...formState, business_name: value })}
						/>
						<Input 
							name="email"
							type="email"
							label="Email *"
							value={formState.email || props.userEmail || ""}
							onChange={(value) => setFormState({ ...formState, email: value })}
						/>
						<textarea
							name="message"
							rows="5"
							value={formState.message || ""}
							onChange={(e) => setFormState({ ...formState, message: e.target.value })}
							placeholder="Message"
						></textarea>
						{formSubmitStatus === "success" && <div className="alert success top">Sent! We will get back to you soon.</div>}
						{formSubmitStatus === "failure" && <div className="alert failure top">Oh no! Something went wrong, please try again later.</div>}
						<Button type="submit" name="send_message" className="button outlined" label="send"/>
					</form>
				</div>
			</div>
			</FadeInSection>
			</section>
		</div>
}


export default Contact;