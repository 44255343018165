import React from "react"

function Checkbox(props) {
	return <div className="input checkbox">
		<svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" onClick={(_e) => props.onChange && props.onChange(!props.selected)}>
		<rect x="0.5" y="0.5" width="16" height="16" fill="#0E0E0E" stroke="#525252" rx="2"/>
			{props.selected && <rect x="4.5" y="4.5" width="8" height="8" fill="#FAFAFA" stroke="FAFAFA" rx="2"/>}
		</svg>
		<input tabIndex="-1" type="checkbox" id={props.value} value={props.value} checked={props.selected} onChange={(_e) => {}} hidden/>
		<label htmlFor={props.value}>{props.label || props.children}</label>
	</div>
}

export default Checkbox;